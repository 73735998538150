import flagMAR from '../assets/images/flag-mar.svg';
import flagUSA from '../assets/images/flag-usa.svg';
import flagFR from '../assets/images/flag-fr.svg';
const translations = {
    en: {
        navigation: {
            slogan: "Everything You Need at the Tap of a Button",
            careers: "Why Join the Done Family?",
            fitCheck: "Is Done Right for You?",
            signup: "Be Part of the Family",
            rewards: "Exclusive Rewards for the Done Family",
            contact: "Would you like to know more?"
        },
        hero: {
            title: "Morocco's First Super-App to Simplify Your Life",
            slogan: "Everything You Need at the Tap of a Button",
            intro: {
                primary: "Imagine a life that's simpler and more convenient with Done, the super-app that brings everything you need into one place.",
                secondary: "We're dedicated to making your day more comfortable and enjoyable through our diverse and innovative services."
            }
        },

        services: {
            food: {
                title: "Food Delivery",
                description: "Craving a delicious meal without the hassle? We deliver food to you anywhere, anytime."
            },
            transport: {
                title: "Transportation Services",
                description: "Need a reliable ride? We're here to serve you around the clock."
            },
            groceries: {
                title: "All Your Daily Needs",
                description: "Get your daily essentials—groceries, gifts, medicines—anything you need quickly and easily."
            },
            wallet: {
                title: "Convenient Financial Services",
                description: "No need to carry cash. Transfer money, shop, and pay bills effortlessly with Done."
            },
            delivery: {
                title: "Parcel Delivery",
                description: "Have a package to send? Let us handle it for you."
            }
        },

        brands: {
            title: "All Your Favorite Brands in One Place",
            description: "Discover the latest offers and trends from the biggest brands at your fingertips, delivered to you in less than an hour."
        },

        careers: {
            title: "Why Join the Done Family?",
            reasons: {
                vision: {
                    title: "Together Towards a Billion Dollars",
                    description: "We want you to be part of our journey to create Morocco's first 100% homegrown billion-dollar company. Let's put Morocco on the global tech map."
                },
                innovation: {
                    title: "Transforming Morocco into a Tech Hub",
                    description: "We're striving to make Morocco a center for technology and innovation, and we need your talents to contribute to this transformation."
                },
                impact: {
                    title: "Simplifying People's Lives",
                    description: "We believe technology can simplify lives, and we're working hard to make that a reality."
                },
                talent: {
                    title: "Leveraging Local and Global Talent",
                    description: "We value the great talents in our country and abroad, offering you the opportunity to develop your skills and contribute to our shared success."
                },
                growth: {
                    title: "Develop Your Skills",
                    description: "We provide a stimulating environment for you to learn and grow. Face new challenges and acquire skills that help you reach your full potential."
                },
                culture: {
                    title: "An Inspiring Work Culture",
                    description: "Work with top-tier colleagues and experts, learning daily by solving complex problems."
                },
                creativity: {
                    title: "Innovation and Creativity",
                    description: "We encourage out-of-the-box thinking and support your innovative ideas."
                }
            },

            fitCheck: {
                title: "Is Done Right for You?",
                intro: "We understand that Done might not be the perfect fit for everyone, and that's okay. This opportunity might not be suitable for you if you:",
                considerations: {
                    motivation: {
                        title: "Are Just Looking for a Salary",
                        description: "We're seeking people who share our passion and aspiration to make a positive change."
                    },
                    flexibility: {
                        title: "Prefer a Traditional 9-to-6 Work Environment",
                        description: "We work with passion, which may require flexibility in working hours to achieve our goals."
                    },
                    adaptability: {
                        title: "Need a Fixed and Routine Job",
                        description: "We're a startup that values innovation and flexibility in the workplace."
                    },
                    learning: {
                        title: "Prefer Traditional Training",
                        description: "We offer practical learning opportunities and real challenges that contribute to your skill development."
                    },
                    culture: {
                        title: "Desire a Luxurious Office and Traditional Hierarchy",
                        description: "We focus on growth and customer experience, believing in teamwork beyond titles and appearances."
                    }
                },
                conclusion: "If you're looking for a unique experience and personal growth in a stimulating environment, then Done is the right place for you.",
                cta: "Fill Out This Form to Join Us"
            }
        },

        mission: {
            title: "Our Mission",
            vision: {
                primary: "We aim to create the largest number of job opportunities in Morocco and Africa, and to develop technology to serve the community.",
                secondary: "We strive to make your lives easier by offering faster and more comfortable transportation experiences, and making daily transactions like ordering food, groceries, and managing financial payments easier than ever.",
                tertiary: "At Done, we hope to add a touch of simplicity and ease to your lives, alongside our pioneering aspirations to build a Moroccan startup that inspires the world."
            }
        },

        rewards: {
            title: "Exclusive Rewards for the Done Family",
            description: "Become part of the Done family and enjoy gifts and extra credit to use on any service you desire."
        },

        signup: {
            title: "Be Part of the Family",
            description: "Sign up now to receive our news and be the first to access our services.",
            form: {
                fullName: "FullName",
                email: "E-mail",
                city: "City",
                phone: "Phone",
                register: "Register"
            }
        },
        copyright: "All rights reserved © 2024 Done.ma"
    },
    ar: {
        navigation: {
            slogan: "كل ما تحتاجه بضغطة زر",
            careers: "لماذا عليك الإنضمام إلى عائلة Done؟",
            fitCheck: "هل Done مناسبة لك؟",
            signup: "كن جزءًا من العائلة",
            rewards: "مكافآت خاصة لعائلة Done",
            contact: "هل تريد المزيد من المعلومات؟"
        },
        hero: {
            title: "أول تطبيق شامل بالمغرب، سيجعل حياتك أسهل",
            slogan: "كل ما تحتاجه بضغطة زر",
            intro: {
                primary: "تخيّل حياة أكثر بساطة وسهولة مع Done، التطبيق الذي يجمع كل ما تحتاجه في مكان واحد.",
                secondary: "نسعى لجعل يومك أكثر راحة ومتعة من خلال خدماتنا المتعددة والمبتكرة."
            }
        },

        services: {
            food: {
                title: "توصيل الطعام",
                description: "ترغب في وجبة لذيذة دون عناء؟ نوصّل لك الطعام إلى أي مكان وفي أي وقت."
            },
            transport: {
                title: "خدمات النقل",
                description: "تحتاج إلى وسيلة تنقل موثوقة؟ نحن هنا لخدمتك على مدار الساعة."
            },
            groceries: {
                title: "كل احتياجاتك اليومية",
                description: "احصل على مستلزماتك اليومية، بقالة، هدايا، أدوية، أي شيء تحتاجه بسهولة وسرعة."
            },
            wallet: {
                title: "خدمات مالية مريحة",
                description: "لا داعي لحمل النقود. حوّل الأموال، اشترِ، وادفع الفواتير بكل سهولة عبر Done."
            },
            delivery: {
                title: "توصيل الطرود",
                description: "لديك طرد ترغب في إرساله؟ دعنا نتولى المهمة نيابةً عنك."
            }
        },

        brands: {
            title: "كل علامتك التجارية المفضلة في مكان واحد",
            description: "احدث العروض واخر الصياحات من اكبر العلامات التجارية بين يديك وتصل اليك في اقل من ساعة"
        },

        careers: {
            title: "لماذا عليك الإنضمام إلى عائلة Done؟",
            reasons: {
                vision: {
                    title: "معًا نحو مليار دولار",
                    description: "نرغب في أن تكون جزءًا من رحلتنا لخلق أول شركة مغربية 100% بقيمة مليار دولار. دعنا نضع المغرب على خريطة التكنولوجيا العالمية."
                },
                innovation: {
                    title: "تحويل المغرب إلى مركز تكنولوجي",
                    description: "نسعى لجعل المغرب محورًا للتكنولوجيا والابتكار، ونحتاج إلى مواهبك للمساهمة في هذا التحول."
                },
                impact: {
                    title: "تبسيط حياة الناس",
                    description: "نؤمن بأن التكنولوجيا يمكنها تبسيط حياة الناس، ونعمل جاهدين لتحقيق ذلك."
                },
                talent: {
                    title: "الاستفادة من المواهب المحلية والعالمية",
                    description: "نقدر المواهب الكبيرة الموجودة في بلدنا وفي الخارج، ونوفر لك الفرصة لتطوير مهاراتك والمساهمة في نجاحنا المشترك."
                },
                growth: {
                    title: "تطوير مهاراتك",
                    description: "نوفر بيئة محفزة لتتعلم وتنمّي قدراتك. واجه تحديات جديدة واكتسب مهارات تساعدك على تحقيق إمكاناتك الكاملة."
                },
                culture: {
                    title: "ثقافة عمل ملهمة",
                    description: "اعمل مع نخبة من الزملاء والخبراء، وتعلم يوميًا من خلال حل المشكلات المعقدة."
                },
                creativity: {
                    title: "ابتكار وإبداع",
                    description: "نشجع التفكير خارج الصندوق وندعم أفكارك المبتكرة."
                }
            },

            fitCheck: {
                title: "هل Done مناسبة لك؟",
                intro: "ندرك أن Done قد لا يكون الخيار المثالي للجميع، وهذا لا بأس به. قد لا تكون هذه الفرصة مناسبة لك إذا كنت:",
                considerations: {
                    motivation: {
                        title: "تبحث عن راتب فقط",
                        description: "نحن نبحث عن أشخاص يشاركوننا الشغف والتطلع لتحقيق تغيير إيجابي."
                    },
                    flexibility: {
                        title: "تفضل بيئة عمل تقليدية من 9 صباحًا إلى 6 مساءً",
                        description: "نعمل بشغف وقد يتطلب ذلك مرونة في ساعات العمل لتحقيق أهدافنا."
                    },
                    adaptability: {
                        title: "تحتاج إلى عمل ثابت وروتيني",
                        description: "نحن شركة ناشئة تقدر الابتكار والمرونة في بيئة العمل."
                    },
                    learning: {
                        title: "تفضل التدريب التقليدي",
                        description: "نوفر فرص تعلم عملية وتحديات حقيقية تساهم في تطوير مهاراتك."
                    },
                    culture: {
                        title: "ترغب بمكتب فاخر وتسلسل هرمي تقليدي",
                        description: "نركز على النمو وتجربة العملاء، ونؤمن بالعمل الجماعي بعيدًا عن الألقاب والمظاهر."
                    }
                },
                conclusion: "إذا كنت تتطلع إلى تجربة فريدة وتطوير ذاتك في بيئة محفزة، فإن Done هو المكان المناسب لك.",
                cta: "إملى هذه الإستمارة للإنضمام إلينا"
            }
        },

        mission: {
            title: "مهمتنا",
            vision: {
                primary: "نهدف إلى خلق أكبر عدد من فرص العمل في المغرب وأفريقيا، وتطوير التكنولوجيا لخدمة المجتمع.",
                secondary: "نسعى لتسهيل حياتكم عبر منحكم تجربة تنقل أكثر سرعة وراحة، وجعل التعاملات اليومية كطلب الطعام والبقالة وإدارة المدفوعات المالية وغيرها أسهل من أي وقت مضى.",
                tertiary: "نأمل في Done إلى إضافة لمسة من البساطة والسهولة لحياتكم، جنبًا إلى جنب مع تطلعاتنا الرائدة لبناء شركة ناشئة مغربية تلهم العالم."
            }
        },

        rewards: {
            title: "مكافآت خاصة لعائلة Done",
            description: "كن جزء من عائلة Done واستمتع بهدايا ورصيد إضافي لتستخدمه في أي خدمة ترغب بها."
        },

        signup: {
            title: "كن جزءًا من العائلة",
            description: "تسجّل الآن لتصلك أخبارنا وتكون أول من يحصل على خدماتنا",
            form: {
                fullName: "الإسم الكامل",
                email: "البريد الإلكتروني",
                city: "المدينة",
                phone: "الهاتف",
                register: "تسجّل"
            }
        },
        copyright: " جميع الحقوق محفوظة لدى © 2024 Done.ma"
    },
    mar: {
        navigation: {
            slogan: "أي حاجة بغيتها غير بكليك",
            careers: "علاش خاصك تكون معانا فـ Done؟",
            fitCheck: "واش Done تتناسبك؟",
            signup: "مرحبا بيك فالعائلة",
            rewards: "العائلة كنتهلاو فيها",
            contact: "بغيتي تعرف معلومات كثر؟"
        },
        hero: {
            title: "أول تطبيق شامل فالمغرب، غادي يسهل حياتك!",
            slogan: "أي حاجة بغيتها غير بكليك",
            intro: {
                primary: "تخيّل حياة أسهل وأبسط مع Done، التطبيق لي كيجمّع لك كلشي فبلاصة وحدة.",
                secondary: "حنا هنا باش نردو نهارك زوين وممتع بليسيرفيس دياولنا المتنوعين والجداد."
            }
        },

        services: {
            food: {
                title: "بغيتي تاكل؟",
                description: "أي حاجة تشهيتيها نجيبوها ليك فين ما كنتي وفي أي وقت بغيتي!"
            },
            transport: {
                title: "خاصك طرونسوبر؟",
                description: "أحسن طونوبيل بأحسن شيفور يكونو عندك، حنا هنا 24/24 باش نوصلوك لأي بلاصة بغيتي."
            },
            groceries: {
                title: "تسيفط شي كولية؟",
                description: "عندك كولية، صغيرة ولا كبيرة؟ تسيفطها لطنجة ولا ولكويرة؟ متهزهش همها حنا نوصلوها."
            },
            delivery: {
                title: "نتا غير أمر",
                description: "شي حاجة من الحانوت؟ الدوا من فارمسيان؟ شي كادو تسيفطو لحبيب القلب؟ نتا غير أمر وفرمشة عين حنا معاك."
            },
            wallet: {
                title: "متهز الهم للفلوس",
                description: "علاش غادي تهز معاك البزطام و Done فجيبك؟ سيفط الفلوس، شري، خلّص أي فاكتورة بسهولة مع Done."
            }
        },

        brands: {
            title: "كاع الماركات لتيعجبوك فبلاصة وحدة",
            description: "أخر مكاين فالعروض وآخر الموديلات من أكبر الماركات بين يديك، وتوصلك في أقل من ساعة!"
        },

        careers: {
            title: "علاش خاصك تكون معانا فـ Done؟",
            reasons: {
                vision: {
                    title: "يديك فيدينا باش نوصلو لمليار دولار",
                    description: "بغيناك تكون جزء من رحلتنا لخلق أول شركة مغربية 100% تتسوا مليار دولار. خلينا نحطّو المغرب على خريطة التكنولوجيا العالمية!"
                },
                innovation: {
                    title: "تحويل المغرب لمركز تكنولوجي",
                    description: "كنطمحو نردو المغرب محور التكنولوجيا والابتكار، وحنا محتاجين للموهبة ديالك باش تساهم فهاد التحوّل."
                },
                impact: {
                    title: "نسهلو حياة الناس",
                    description: "كنآمنو بلي التكنولوجيا تقدر تتسهل حياة الناس، وحنا كنخدمو من نيتنا باش نوصول لهاد الهدف."
                },
                talent: {
                    title: "الاستفادة من مواهب ولاد البلاد والأجانب",
                    description: "كنقدّرو المواهب الكبيرة اللي كاينة فبلادنا وفالخارج، وكنعطيوك الفرصة تطوّر مهاراتك وتساهم فنجاحنا جميع."
                },
                growth: {
                    title: "تطوير مهاراتك",
                    description: "كنوفرو بيئة محفّزة باش تتعلّم وتنمّي قدراتك. أجي معانا فكل نهار تحدي جديد تتعلم منو ونعاونوك باش تخرج الوحش لعندك فالداخل ديالك."
                },
                culture: {
                    title: "ثقافة عمل ملهمة",
                    description: "أجي تخدم مع نخبة ديال الشباب والخبراء، وتعلّم كل نهار حاجة جديدة وسط مشاكل ثقنية معقّدة."
                },
                creativity: {
                    title: "ابتكار وإبداع",
                    description: "كنشجّعو التفكير المسطي ولمعندوش حدود وكندعمو الأفكار لتتبان فالأول مستحيلة."
                }
            },
            fitCheck: {
                title: "واش Done تتناسبك؟",
                intro: "ماشي ضروري تكون Done الاختيار المثالي لكلشي، وما عندناش مشكل فهاد الشي. ممكن منصلاحوش ليك إلا كنت",
                considerations: {
                    motivation: {
                        title: "كتقلب على الصالير",
                        description: "حنا كنقلبو على ناس كيشاركونا الشغف والطموح باش نوصلو لتغيير إيجابي."
                    },
                    flexibility: {
                        title: "باغي تخدم من 9 حتى 6 دالعشيا",
                        description: "حنا خدامين بحب وهاد الشي علاش مخصناش ناس فاش تتوصلش 5:59 تيطفيو التيلفون."
                    },
                    adaptability: {
                        title: "بغيتي خدمة روتينية",
                        description: "حنا ستارت أب كولشي تيقدر يدير كولشي وكلنا مدراء."
                    },
                    learning: {
                        title: "بغيتي 1+1=2",
                        description: "تنأمنو بلي تقدر تساوي 2 وتقدر تساوي 17، خدمتنا كلها تحديات والمغامرة وإكتشاف العجب العجاب"
                    },
                    culture: {
                        title: "بغيتي بيرو مستيلي ودغيا تزيد كراد",
                        description: "المدير ديالنا كاع معندو بيرو، التركيز ديالنا على الخدمة كثر من الزواق."
                    }
                },
                conclusion: "ولا كنتي بغيتي تعيش شي حاجة هاربة وفشكل وتطور راسك فجو واعر ديال الخدمة، Done هي أحسن بلاصة تقدر تجي ليها!",
                cta: "عمّر هاد الإستمارة باش تولي واحد منا!"
            }
        },
        mission: {
            title: "المهمة ديالنا",
            vision: {
                primary: "الطموح ديالنا نخلقو أكبر عدد ديال فرص الشغل فالمغرب وإفريقيا، ونطوّرو التكنولوجيا باش نعاونو المجتمع ديالنا.",
                secondary: "الهدف ديالنا نبسّطو حياتكم ونعطيوكوم تجربة واعرة ومستيلية، ونردو التعاملات اليومية ديالنا بحال تكوموندي الماكلة، ولا التقضية من الحانوت، ولا تخلص الفلوس شي حاجة سهل من الساهولية.",
                tertiary: "فـ Done، كنخدمو باش نزيدو لمسة ديال البساطة والسهولة لحياتكم، وبغينا نبنيو شركة ناشئة مغربية كتلهم العالم."
            }
        },

        rewards: {
            title: "العائلة كنتهلاو فيها",
            description: "كون واحد من عائلة Done واستافد من كادوات ومن رصيد تخدم بيه وتشري بيه أي حاجة بغيتي!"
        },

        signup: {
            title: "مرحبا بيك فالعائلة",
            description: "تسجل دابا باش تكون نتا أول واحد تتوصلو خبارنا، وأول واحد تيجرب السيرفيس ديالنا",
            form: {
                fullName: "الإسم الكامل",
                email: "البريد الإلكتروني",
                city: "المدينة",
                phone: "الهاتف",
                register: "تسجّل"
            }
        },
        copyright: " جميع الحقوق محفوظة لدى © 2024 Done.ma"
    },
    fr: {
        navigation: {
            slogan: "Tout Ce Dont Vous Avez Besoin en Un Seul Clic",
            careers: "Pourquoi Rejoindre Done Community ?",
            fitCheck: "Done Est-Il Fait Pour Vous ?",
            signup: "Faites Partie de la Done Community",
            rewards: "Récompenses Exclusives pour la Done Community",
            contact: "Vous souhaitez en savoir plus ?"
        },
        hero: {
            title: "La Super-App Marocaine Qui Vous Facilite la Vie",
            slogan: "Tout Ce Dont Vous Avez Besoin en Un Seul Clic",
            intro: {
                primary: "Découvrez Done, la Super-App qui rassemble tout ce dont vous avez besoin.",
                secondary: "Simplifiez votre quotidien avec nos services innovants pour une vie agréable et connectée."
            }
        },

        services: {
            food: {
                title: "Livraison de repas",
                description: "Un restaurant ? Un clic. On vous livre vos plats préférés où et quand vous voulez."
            },
            transport: {
                title: "Transport VTC",
                description: "Besoin d’un trajet fiable ? Montez à bord, on s’occupe du reste, 24h/24."
            },
            groceries: {
                title: "Vos Besoins Quotidiens",
                description: "Courses, cadeaux, para-pharmacie ? Tout ce qu’il vous faut, livré avec soin et rapidement."
            },
            wallet: {
                title: "Services Financiers",
                description: "Sans cash, sans stress. Payez, transférez, achetez en un clin d’œil avec Done Wallet."
            },
            delivery: {
                title: "Livraison de Colis",
                description: "Un colis à envoyer ? Confiez-le-nous, on le livre avec soin et rapidité."
            }
        },

        brands: {
            title: "Toutes Vos Marques Préférées en Un Seul Endroit",
            description: "Profitez des meilleures offres des grandes marques, livrées chez vous en un temps record."
        },

        careers: {
            title: "Pourquoi Rejoindre Done Community ?",
            reasons: {
                vision: {
                    title: "Ensemble vers la premiere licorne Tech Au Maroc",
                    description: "Participez à créer la première entreprise marocaine valorisée à un milliard de dollars. Faisons du Maroc un acteur technologique mondial."
                },
                innovation: {
                    title: "Vision Digital Maroc 2030",
                    description: "Utilisez vos talents pour faire du Maroc un centre d'innovation et de technologie tout en s'allignant avec la vision 2030 et la volonte de sa majeste le roi Mohamed 6."
                },
                impact: {
                    title: "Simplifier la Vie des Gens",
                    description: "Facilitez le quotidien de tous grâce à la puissance de la technologie."
                },
                talent: {
                    title: "Valoriser les talents locaux et internationaux",
                    description: "Grandissez avec nous tout en contribuant à notre succès collectif."
                },
                growth: {
                    title: "Développez Vos Compétences",
                    description: "Relevez de nouveaux défis dans un environnement stimulant pour atteindre votre plein potentiel."
                },
                culture: {
                    title: "Une Culture de Travail Inspirante",
                    description: "Collaborez avec des experts passionnés et résolvez ensemble des problèmes complexes."
                },
                creativity: {
                    title: "Innovation et Créativité",
                    description: "Exprimez vos idées novatrices pour façonner l'avenir de la technologie."
                }
            },

            fitCheck: {
                title: "Done Est-Il Fait Pour Vous ?",
                intro: "Chez Done, nous savons que notre entreprise ne conviendra pas à tout le monde, Posez-vous ces questions avant de nous rejoindre :",
                considerations: {
                    motivation: {
                        title: "Plus qu'un simple salaire ?",
                        description: "Nous recherchons des passionnés prêts à changer les choses, pas seulement des employés."
                    },
                    flexibility: {
                        title: "Vous voulez plus qu'un emploi 9h-18h ?",
                        description: "Notre passion nous pousse parfois à sortir des horaires traditionnels pour atteindre nos objectifs."
                    },
                    adaptability: {
                        title: "Besoin d’une routine fixe ?",
                        description: "En tant que startup, nous misons sur l’innovation et la flexibilité au quotidien."
                    },
                    learning: {
                        title: "Vous aimez les formations standards ?",
                        description: "Chez Done, vous apprendrez en relevant des défis réels qui font évoluer vos compétences."
                    },
                    culture: {
                        title: "Bureau luxueux et hiérarchie rigide ?",
                        description: "Nous misons sur l’esprit d’équipe et la croissance, au-delà des apparences et des titres."
                    }
                },
                conclusion: "Si vous recherchez une expérience unique et une croissance personnelle dans un environnement stimulant, Done est fait pour vous !",
                cta: "Rejoignez-nous !"
            }
        },

        mission: {
            title: "Notre Mission",
            vision: {
                primary: "Créer des opportunités d'emploi au Maroc et en Afrique en développant une technologie au service de la communauté.",
                secondary: "Simplifiez votre vie avec nos solutions de transport rapides, commandes de repas, courses et paiements facilités.",
                tertiary: "Chez Done, nous apportons simplicité à votre quotidien, tout en bâtissant une startup marocaine inspirante à l'international."
            }
        },

        rewards: {
            title: "Récompenses Exclusives pour la Done Community",
            description: "Rejoignez Done et profitez de cadeaux exclusifs et de crédits bonus pour vos services préférés !"
        },

        signup: {
            title: "Faites Partie de la Done Community",
            description: "Inscrivez-vous pour recevoir nos actualités et services exclusifs. Simplifiez votre vie avec Done !",
            form: {
                fullName: "Nom et Prénom",
                email: "E-mail",
                city: "Ville",
                phone: "Téléphone",
                register: "Rejoindre"
            }
        },
        copyright: "Tous droits réservés © 2024 Done.ma"
    }
};

const SUPPORTED_LANGUAGES = ['en', 'fr', 'ar', 'mar'];
const DEFAULT_LANGUAGE = 'mar';

const getCurrentLanguage = (() => {
    let cachedLanguage = null;
    return () => {
        if (cachedLanguage) return cachedLanguage;

        const urlLang = window.location.pathname.split('/')[1];
        if (SUPPORTED_LANGUAGES.includes(urlLang)) {
            cachedLanguage = urlLang;
            return urlLang;
        }

        const browserLang = navigator.language.split('-')[0];
        cachedLanguage = SUPPORTED_LANGUAGES.includes(browserLang)
            ? browserLang
            : DEFAULT_LANGUAGE;

        return cachedLanguage;
    };
})();

// Hnadle nested translation
function getNestedTranslation(obj, path) {
    return path.split('.').reduce((current, key) =>
        current && current[key] !== undefined ? current[key] : undefined,
        obj
    );
}

// Language flag and code mapping
const LANGUAGE_MAP = {
    'mar': { flag: flagMAR, code: 'الدارجة' },
    'ar': { flag: flagMAR, code: 'العربية' },
    'en': { flag: flagUSA, code: 'English' },
    'fr': { flag: flagFR, code: 'Français' }
};

function changeLanguage(lang) {
    const languageInfo = LANGUAGE_MAP[lang] ?? LANGUAGE_MAP[DEFAULT_LANGUAGE];

    const flagElements = [
        document.getElementById('current-flag'),
        document.getElementById('current-flag-menu')
    ];
    const langElements = [
        document.getElementById('current-lang'),
        document.getElementById('current-lang-menu')
    ];

    flagElements.forEach(el => el && (el.src = languageInfo.flag));
    langElements.forEach(el => el && (el.textContent = languageInfo.code));

    const translationElements = document.querySelectorAll('[data-translation]');
    translationElements.forEach(el => {
        const key = el.dataset.translation;
        const translatedText = getNestedTranslation(translations[lang], key);

        if (translatedText !== undefined) {
            el.textContent = translatedText;
        } else {
            console.warn(`Translation missing for key: ${key}`);
        }
    });

    document.documentElement.lang = lang;
}

function setupMenuInteractions() {
    const menuToggle = document.getElementById('menu-toggle');
    const menuItems = document.querySelector('.menu-items');
    const wrapper = document.querySelector('.wrapper');

    function closeMenu() {
        wrapper.classList.remove('menu-open');
        menuToggle.checked = false;
    }

    const events = ['click', 'touchstart'];
    events.forEach(eventType => {
        document.addEventListener(eventType, e => {
            // Prevent closing if menu toggle or menu items are clicked
            if (e.target === menuToggle || menuItems.contains(e.target)) {
                return;
            }
            closeMenu();
        }, { passive: true });
    });

    menuToggle.addEventListener('change', function () {
        wrapper.classList.toggle('menu-open', this.checked);
    });

    const menuLinks = document.querySelectorAll('.menu-items a');
    menuLinks.forEach(link => {
        link.addEventListener('click', closeMenu);
        link.addEventListener('touchstart', closeMenu, { passive: true });
    });
}

// Language selection setup
function setupLanguageSelection() {
    const languageItems = document.querySelectorAll('.lang-item');
    languageItems.forEach(item => {
        const handler = (e) => {
            e.preventDefault();
            const lang = item.getAttribute('data-lang');
            changeLanguage(lang);
            history.replaceState(null, '', `/${lang}`);
        };

        item.addEventListener('click', handler);
        item.addEventListener('touchstart', handler, { passive: true });
    });
}

// Initialization
document.addEventListener('DOMContentLoaded', () => {
    const currentLanguage = getCurrentLanguage();
    changeLanguage(currentLanguage);
    history.replaceState(null, '', `/${currentLanguage}`);

    setupMenuInteractions();
    setupLanguageSelection();
});